$f-reg: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
html {
  // // 1440 px -> 8px
  font-size: 0.556vw;
  // font-size: 8px;
  font-family: $f-reg;
  max-width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
  font-display: swap;
}
* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
// Colors
// COLORS
$c-gray-20: #151515;
$c-gray-30: #373737;
$c-gray-50: #6d6d6d;
$c-gray-60: #5f6475;
$c-gray-70: #a6acbe;
$c-gray-80: #e4e6ea;
$c-gray-95: #f8f8f8;
$c-gray-98: #fbfcff;
$c-white: #ffffff;

$c-red: #f44242;
$c-yellow: #ffb800;
$c-green: #48d53b;

$c-primary: #65a5dc;
$c-primary-hover: #253872;
$c-primary-active: #3b5093;

// typograpgy
$fs-h1: 5rem;
$fs-h1-line: 139%;

$fs-h2: 3.75rem;
$fs-h2-line: 133%;

$fs-h3: 3.125rem;
$fs-h3-line: 120%;

$fs-h4: 2.5rem;
$fs-h4-line: 125%;

$fs-p1: 2.25rem;
$fs-p1-line: 3.75rem;

$fs-p2: 2rem;
$fs-p2-line: 3.125rem;

$fs-p3: 1.75rem;
$fs-p3-line: 2.5rem;

$fs-p4: 1.5rem;
$fs-p4-line: 125%;

body {
  font-size: $fs-p4;
  line-height: 2rem;
  font-weight: 400;
}
.h1-text {
  font-size: $fs-h1;
  line-height: $fs-h1-line;
}
.h2-text {
  font-size: $fs-h2;
  line-height: $fs-h2-line;
}
.h3-text {
  font-size: $fs-h3;
  line-height: $fs-h3-line;
}
.h4-text {
  font-size: $fs-h4;
  line-height: $fs-h4-line;
}
.lg-text {
  font-size: $fs-p1;
  line-height: $fs-p1-line;
}
.md-text {
  font-size: $fs-p2;
  line-height: $fs-p2-line;
}
.sm-text {
  font-size: $fs-p3;
  line-height: $fs-p3-line;
}
.caption-text {
  font-size: $fs-p4;
  line-height: $fs-p4-line;
}

.text-link {
  transition: all 0.3s;

  &:hover {
    // color: $c-blue;
    text-decoration: underline;
  }
}

.d-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  // justify-content: flex-end;
}
.d-flex.column {
  flex-direction: column;
}
.d-flex.align-start {
  align-items: flex-start;
}
.d-flex.align-end {
  align-items: flex-end;
}
.d-flex.align-center {
  align-items: center;
}
.d-flex.justify-center {
  justify-content: center;
}
.d-flex.justify-start {
  justify-content: flex-start;
}
.d-flex.justify-end {
  justify-content: flex-end;
}
.f-column {
  flex-direction: column !important;
}
.f-row {
  flex-direction: row !important;
}
.flex-1 {
  flex-grow: 1;
}
.flex-50 {
  flex: 0 1 50%;
}
.flex-30 {
  flex: 0 1 33%;
}
.flex-25 {
  flex: 0 1 25%;
}
.card {
  display: flex;
  flex-direction: column;
  background: $c-white;
  box-shadow: 0px 1rem 3.5rem rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
}

.app-error {
  &-msg {
    font-size: 1.8rem;
    font-weight: bold;
  }
}
