.tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .tabs-header {
        display: flex;
        align-items: center;
        margin-bottom: 3rem;
        min-height: 6rem;

        .tabs-header_item {
            @extend .sm-text;
            position: relative;
            margin-right: 3.75rem;
            cursor: pointer;
            a {
                color: inherit;
                text-decoration: none;
            }
            &.active, a.active {

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -0.5rem;
                    left: 0;
                    width: 100%;
                    height: 0.25rem;
                    border-radius: 0.5rem;
                    background: $c-primary-active;
                }
            }
            a.active {
                color: $c-primary-active;
            }
        }
    }
}