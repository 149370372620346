.switch {
    position: relative;
    background: $c-gray-80;
    border-radius: 3.75rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;

    .switch__text {
        width: 4.25rem;
        @extend .sm-text;
        text-align: center;
        transition: all 0.3s;
    }
    .switch__dot {
        width: 2.625rem;
        height: 2.625rem;
        background: $c-red;
        transition: all 0.3s;
        border-radius: 50%;
    }

    &.active {
        flex-direction: row-reverse;
        .switch__dot {
            background: #42D58E;
        }
    }
}