.profile-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 3rem 2.5rem;

    .MuiFormControl-root {
        max-width: 46rem;
        .MuiSelect-outlined.MuiSelect-outlined {
            width: 100%;
        }
    }
}