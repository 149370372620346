@import './avatar';
@import './button';
@import './table';
@import './modal';
@import './tabs';
@import './file-input';
@import './switch';

.icon-wrapper svg {
    width: 100%;
    height: 100%;

    &:hover {
        cursor: pointer;
    }
}

.dialog-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(26, 25, 25, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1000;

    .dialog {
        background: $c-white;
        border: 0.125rem solid $c-gray-95;
        box-sizing: border-box;
        box-shadow: 0px 1rem 2.5rem rgba(0, 0, 0, 0.04);
        border-radius: 0.75rem;
        position: relative;
        padding: 1.5rem 3rem;

        .dialog__close-btn {
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
    }
}