.avatar-wrapper {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.125rem solid $c-gray-98;
    box-shadow: 0px 1rem 2.5rem rgba(0, 0, 0, 0.04);
    
    img {

    }
}