.table {
    background: $c-white;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .table-header-row {
        display: flex;
        align-items: center;
        background: linear-gradient(281.11deg, #B3CDFE 0%, #B3CEFE 0.01%, #BFE9FC 100%);
        height: 4.5rem;
        align-content: center;

        .table-header__item {
            display: flex;
            align-items: center;
            box-sizing: content-box;
            padding: 0 0.5rem 0 0.5rem;
            font-weight: bold;

            &:first-child {
                padding: 0 1.25rem 0 2rem;
            }
            svg {
                // fill: $c-black;
                width: 1.75rem;
                height: 1.75rem;
                transition: all 0.3s;
            }
            &.active {
                svg {
                    // fill: $c-dark;
                    transform: rotate(180deg);
                }
            }
        }
    }
    .table .table-header-row {
        border-radius: 0.5rem 0.5rem 0 0;
    }
    .table-row {
        display: flex;
        align-items: center;
        padding: 2rem 0;
        background: $c-white;
        border-radius: 0.5rem;
        position: relative;
        min-height: 5.5rem;
        max-height: max-content;
        border-bottom: 0.125rem solid $c-gray-80;

       .table-row__cell-clickable {
            cursor: pointer;
            transition: all 0.3s;
            display: flex;
            justify-content: center;
        }

        &:hover {
            filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.15));
            position: relative;
            z-index: 100;
        }
        .table-row__cell {
            display: flex;
            align-items: center;
            box-sizing: content-box;
            padding: 0 2rem 0 0.5rem;

            img {
                margin: auto;
            }

            &:first-child {
                padding: 0 1.25rem 0 2rem;
            }
        }
        &::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 0.5rem;
            top: 0;
            left: 0;
        }
        &.status-red::before {
            background: #F2525A;
        }
    }
    .table-children-row {
        padding: 3rem 2.5rem;
        background: $c-gray-95;
    }
    .table-more-btn {
        margin-top: 1.5rem;
        width: 100%;
        padding: 1.5rem 0;
        border-radius: 0.5rem;
        border: none;
        cursor: pointer;
        background: #F6F8FA;
        outline: none;
    }


}


.pagination {
    margin: 4rem auto 5.5rem;
    display: flex;
    @extend .sm-text;

    .pagination-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        width: 2.75rem;
        color: $c-gray-70;
        transition: all 0.3s;
        cursor: default;
        margin: 0 1.75rem;

        &.active:hover {
            cursor: pointer;
        }

        &.disabled {
            opacity: .35;
        }
    }
    .pagination-outline-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        width: 2.75rem;
        border: 0.125rem solid $c-gray-80;
        color: $c-gray-70;
        border-radius: 0.25rem;
        transition: all 0.3s;
        cursor: pointer;
        margin: 0 0.75rem;

        &.active, &:hover {
            color: $c-primary;
            border: 0.125rem solid $c-primary;
        }
    }
}

.table-filters {
    display: flex;
    align-items: flex-start;
    padding: 2.5rem 2rem;

    .MuiSelect-outlined.MuiSelect-outlined{
        width: 30.75rem;
    }
}
