.data-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3rem 2.5rem;

  &-agentlines-brands {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 2.1rem;

    &-name {
      flex-grow: 1;

      display: flex;
      align-items: center;

      margin: 0.5rem 0.1rem;
      min-width: 20rem;
      max-width: 20rem;
    }
  }
}
