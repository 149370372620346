.MuiTextField-root {
    box-sizing: border-box;
    
    .MuiInputBase-root {
        font-size: 2rem;
        font-family: $f-reg;
    }
}
.MuiFormControl-root {
    box-sizing: border-box;
    font-size: 2rem;
    .MuiOutlinedInput-input {
        padding: 1rem 1.75rem;
    }
    .MuiInputAdornment-positionStart .icon-wrapper {
        margin-right: -2rem;
    }
    .MuiInputBase-root {
        height: 6rem;
        font-size: 2rem;
        font-family: $f-reg;
        background: #FFFFFF !important;
    }
    .MuiSelect-select:focus {
        background: #FFFFFF !important;
    }
    .MuiFormLabel-root {
        font-size: 2rem;
        padding: 0 1rem;
        background: #FFFFFF;
        transform: translate(1.75rem, 1.75rem) scale(1) !important;


        &.Mui-focused {
            margin: 0 -0.5rem;
            padding: 0 0.5rem;
            transform: translate(1.75rem, -1rem) scale(1) !important;

        }
        @extend .sm-text;
    }
    
    .MuiSvgIcon-root {
        width: 2rem;
        height: 2rem;
        right: 1rem;
        top: calc(50% - 1rem);
    }
    .MuiSelect-outlined.MuiSelect-outlined{
        padding-right: 4rem;
        min-height: 6rem;
        max-height: 6rem;
        width: 100%;
        box-sizing: border-box;
    }
}
.MuiAutocomplete-root .MuiInputBase-root {
    min-height: 6rem !important;
    height: auto;
    width: 100%;
}
.MuiAutocomplete-tag {
    margin: 0.375rem !important;
    height: 4rem !important;
    font-size: 1.5rem !important;
    border-radius: 2rem;
    .MuiChip-label {
        padding: 0 1.5rem;
    }
}
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 1rem 4.5rem 1rem 1rem !important;
}
.MuiAutocomplete-endAdornment {
    top: calc(50% - 1.75rem) !important;
}
.date-range {
    max-width: 23.75rem;
    .MuiInputBase-root {
        font-size: 2rem;
        width: 6rem;
    }
    .MuiInput-underline:not(.Mui-disabled):before {
        border: none;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border: none;
    }
}
.MuiIconButton-label {
   
    svg {
        width: 1.75rem;
        height: 1.75rem;
    }
}
.MuiTypography-h4 {
    font-size: 4.25rem !important;
    font-family: $f-reg !important;
}
.MuiTypography-body1, .MuiTypography-subtitle1 {
    font-size: 2rem !important;
    font-family: $f-reg !important;
}
.MuiTypography-body2 {
    font-size: 1.75rem !important;
    font-family: $f-reg !important;
}
.MuiTypography-caption {
    font-size: 1.5rem !important;
    font-family: $f-reg !important;
}
.MuiButton-root {
    font-size: 1.75rem !important;
    font-family: $f-reg !important;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0 !important;
    padding-left: 1rem !important;
}
.custom-text-area {
    outline: none;
    border: 0.125rem solid #c4c4c4;
    border-radius: 0.5rem;
    padding: 1rem;
    font-family: $f-reg !important;
    font-size: 1.75rem;
    
    &:hover {
        border: 0.125rem solid #333;
    }
    &:focus,
    &:focus:hover  {
        border: 0.125rem solid #6cb5f9;
    }
}

.custom-radio {

    .MuiRadio-colorSecondary.Mui-checked {
        color: #65A5DC;
    }
    .PrivateSwitchBase-root-1 {
        padding: 1rem;
    }
    svg.MuiSvgIcon-root {
        width: 2.5rem !important;
        top: unset !important;
    }

    .MuiRadio-colorSecondary:hover, .MuiRadio-colorSecondary.Mui-checked:hover {
        background-color: rgba(101, 165, 220, 0.04);
    }
}