.file-input {
    position: relative;
    min-height: 17.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.125rem dashed #98A1AF;
    border-radius: 0.5rem;
    transition: all 0.3s;

    &:hover {
        background: #F6F8FA;
    }

    input {
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
    &.file-preview {
        padding: 1rem;
        flex-direction: row;
        align-items: stretch;

        .file-preview-image {
            width: 26rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
            }
        }
        .file-input {
            flex-direction: row;
            border: none;
            min-height: unset;
            flex: 1;
        }
    }
    .file-preview-controll {
        display: flex;
        align-items: center;
        padding: 0 1rem 0 1.5rem; 
        background: linear-gradient(281.11deg, #B3CDFE 0%, #B3CEFE 0.01%, #BFE9FC 100%);
        border-radius: 3.75rem;
        width: 35rem;
    }
}
