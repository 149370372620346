.main-page {
    padding: 1.75rem 2.5rem 1.5rem;

    .dashboard-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .dashboard-card {
        flex: 1 1 100%;
        @extend .card;
        background: $c-white; 
        padding: 2.5rem 2rem;
        height: min-content;
    }
    .link-card {
        @extend .card;
        background: $c-white; 
        flex-direction: row;
        min-height: 7.5rem;
        align-items: center;
        flex: 1;
        padding: 0 2rem 0 3rem;
        position: relative;
        a {
            display: flex;
            align-items: center;
        }
        .link-card__date-label {
            @extend .caption-text;
            color: $c-gray-30;
            position: absolute;
            padding: 0.25rem 1rem;
            top: 0;
            right: 0;
            background: linear-gradient(281.11deg, #B3CDFE 0%, #B3CEFE 0.01%, #BFE9FC 100%);
            border-radius: 0px 1rem;
        }
    }
    .dashboard-text-row {
        display: flex;
        padding: 0 2.5rem;
        margin-bottom: 0.75rem;

        .d-flex {
            flex: 1;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    .icon-card {
        @extend .card;
        background: $c-white; 
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.5rem;
        height: 4.5rem;
        filter: drop-shadow(0px 0.5rem 1rem rgba(0, 0, 0, 0.15));
        svg path, svg ellipse {
            stroke: $c-primary;
        }
    }
    hr.line {
        background: $c-gray-80;
        height: 0.125rem;
        border: none;
        margin: 1.5rem -2rem;
    }
}