.auth-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: $c-gray-95;

    .auth-card {
        width: 122.375rem;
        height: 77.75rem;
        box-shadow: 0px 1rem 2.5rem rgba(0, 0, 0, 0.04);
        border-radius: .75rem;
        display: flex;
        align-items: stretch;
        background: $c-white;
    }
    .auth-left {
        width: 43.25rem;
        padding: 6.75rem 7.25rem 0 4.25rem;
        background-size: cover;

        .logo {
            width: 15rem;
            height: 3rem;
        }
    }
    .auth-right {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding-top: 15.625rem;
        .btn {
            width: 31.75rem;
        }

        .MuiTextField-root {
            width: 31.75rem;
        }
        .auth-link {
            &__disabled {
                color: $c-gray-70 !important;
                cursor: default !important;

                &::before {
                    background: $c-gray-70 !important;
                }
            }
            
            position: relative;
            color: $c-primary;
            cursor: pointer;
            @extend .md-text;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 0.125rem;
                background: $c-primary;
                bottom: -0.125rem;
                left: 0;
            }
            &:hover {
                &::before {
                    opacity: 0;
                }
            }
        }
    }
}