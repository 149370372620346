.btn {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 1.25rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  transition: all 0.3s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .textCmp {
    color: inherit !important;
  }
}

.btn-primary {
  color: $c-white;
  background: linear-gradient(90deg, #253872 -6.93%, #77a3c9 108.23%);
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
  border: 0;
  font-weight: 600;
  &:hover {
    background: linear-gradient(90deg, #77a3c9 -6.93%, #77a3c9 108.23%);
  }
  &:active {
    background: linear-gradient(90deg, #253872 -6.93%, #253872 108.23%);
  }
  &:disabled {
    border: 0;
    background: #e7ebf0;
    color: $c-gray-70;
  }
}
.btn-primary-outline {
  color: $c-primary;
  background: $c-white;
  border: 0.125rem solid $c-primary;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
  font-weight: 600;
  svg path {
    stroke: $c-primary;
  }

  &_red {
    border: 0.125rem solid $c-red;

    &:hover {
      svg path {
        stroke: $c-white;
      }
      color: $c-white;
      background: linear-gradient(90deg, $c-red -6.93%, $c-red 108.23%);
    }
    &:active {
      color: $c-white;
      background: linear-gradient(90deg, $c-red -6.93%, $c-red 108.23%);
    }
    &:disabled {
      border: 0;
      background: #e7ebf0;
      color: $c-gray-70;
      svg path {
        stroke: $c-gray-70;
      }
    }
  }

  &:hover {
    svg path {
      stroke: $c-white;
    }
    color: $c-white;
    background: linear-gradient(90deg, #77a3c9 -6.93%, #77a3c9 108.23%);
  }
  &:active {
    color: $c-white;
    background: linear-gradient(90deg, #253872 -6.93%, #253872 108.23%);
  }
  &:disabled {
    border: 0;
    background: #e7ebf0;
    color: $c-gray-70;
  }
}

.btn-outline {
  color: $c-gray-50;
  font-weight: 600;
  border: 0.125rem solid $c-gray-70;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
  svg path {
    stroke: $c-gray-50;
  }
  &:hover {
    svg path {
      stroke: $c-gray-30;
    }
    border: 0.125rem solid $c-gray-30;
    color: $c-gray-30;
  }
  &:disabled {
    border: 0.125rem solid $c-gray-80;
    color: $c-gray-70;
    svg path {
      stroke: $c-gray-70;
    }
  }
}

.btn-flat {
  color: $c-gray-70;
  font-weight: 600;
  svg path {
    stroke: $c-gray-70;
  }
  &:hover {
    svg path {
      stroke: $c-gray-30;
    }
    color: $c-gray-30;
  }

  &:disabled {
    border: 0;
    background: #e7ebf0;
    color: $c-gray-70;
  }

  &.icon {
    padding: 0;
    svg path {
      stroke: inherit;
    }
    &:hover {
      svg path {
        stroke: inherit;
      }
      color: inherit;
    }
  }
}

.btn-white {
  color: $c-gray-70;
  background: $c-white;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
  font-weight: 600;
  svg path {
    stroke: $c-primary;
  }
  &:hover {
    svg path {
      stroke: $c-white;
    }
    color: $c-white;
    background: linear-gradient(90deg, #77a3c9 -6.93%, #77a3c9 108.23%);
  }
  &:active {
    color: $c-white;
    background: linear-gradient(90deg, #253872 -6.93%, #253872 108.23%);
  }
  &:disabled {
    border: 0;
    background: #e7ebf0;
    color: $c-gray-70;
  }
}
