@import './auth.scss';
@import './main.scss';
@import './tasks.scss';
@import './approvement.scss';
@import './users.scss';
@import './data.scss';
@import './shop.scss';
@import './news.scss';
@import './profile.scss';
@import './faq.scss';

.page-layout {
    display: grid;
    grid-template-areas: "m c";
    grid-template-columns: 1fr 4.8fr;
    height: 100vh;
    position: relative;
    max-width: 100%;
    background: $c-gray-95;

    .page-menu {
        grid-area: m;
        height: 100vh;
        padding: 0 1.5rem;
        background: $c-white;
        display: flex;
        flex-direction: column;
        .logo-wrapper {
            height: 7.5rem;
            min-height: 7.5rem;
            display: flex;
            align-items: center;
            padding-left: 2rem;
            .logo { 
                width: 15rem;
                height: 3rem;
            }
        }
        .menu-card {
            @extend .card;
            background: $c-gray-98;
        }
        .menu-item {
            display: flex;
            align-items: center;
            height: 5.5rem;
            padding: 0 1.5rem;
            text-decoration: none;
            color: $c-gray-50; 
            @extend .md-text;
            svg {
                stroke: $c-gray-50;
            }

            &:first-child { 
                border-top-left-radius: inherit;
                border-top-right-radius: inherit;
            } &:last-child {
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;
            }

            &.active {
                background: linear-gradient(281.11deg, rgba(179, 205, 254, 0.2) 0%, rgba(179, 206, 254, 0.2) 0.01%, rgba(191, 233, 252, 0.2) 100%);
                color: $c-primary;
                position: relative;
                &:before {
                    content: '';
                    height: 100%;
                    width: 0.25rem;
                    background: $c-primary;
                    border-radius: 1.25rem;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
                svg path, svg ellipse {
                    stroke: $c-primary;
                }
            }
        }
        .menu-bottom {
            margin: auto -1.5rem 0;
            padding: 0 1rem 3rem;
            border-top: 0.125rem solid #E7EAEE;

            .bottom-card {
                @extend .card;
                height: 8rem;
                flex-direction: row;
                align-items: center;
                padding: 0 1.5rem;
            }
        }
    }
    .page-content {
        grid-area: c;
        height: 100vh;
        height: 100vh;
        display: flex;
        flex-direction: column;
        position: relative;
        .page-content-top {
            height: 7.5rem;
            min-height: 7.5rem;
            background: $c-white;
            display: flex;
            padding: 1.25rem 4.5rem;
            justify-content: flex-end;
        }
        .page-content-box {
            flex: 1;
            overflow-y: scroll;
        }
    }
}