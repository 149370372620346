.users-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 3rem 2.5rem;

    .checkbox-table {
        .table-row__cell:first-child,
        .table-header__item:first-child {
            padding: 0 !important;
            justify-content: center;
            .textCmp  {
                width: 100%;
            }
        }
        .MuiFormControlLabel-root{
            margin: 0 !important;
        }
        .PrivateSwitchBase-root-5 {
            padding: 0;
        }
    }
    .table .table {
        background: none;

        .table-header-row {
            background: $c-gray-95;
        } 
    }
    .modal {
        .MuiOutlinedInput-root{
            width: 46.125rem;
            max-width: 100%;
        }
        .MuiSelect-outlined.MuiSelect-outlined {
            width: 100%;
        }
        hr.line {
            margin: 3rem -1.5rem;
            border: none;
            border-top: 1px solid #ECECEC;
        }
        .MuiCheckbox-root .MuiIconButton-label svg {
            width: 2rem;
            height: 2rem;
        }

        form {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
        }

        .search-wrapper {

            .MuiFormControl-root, .MuiOutlinedInput-root{
                width: 100%;
             }
             .results {
                border: 0.125rem solid #ECECEC;
                border-radius: 0.75rem;
                max-height: 25rem;
                overflow: auto;
             }
             .icon-wrapper {
                cursor: pointer;
             }
        }
        .list-element-wrapper {
            background: #fff;
            box-shadow: 0px 1rem 2.5rem rgba(0, 0, 0, 0.04);
            border-radius: 0.5rem;
            display: flex;
            align-items: center;
            padding: 2.5rem 1.75rem;
        }
    }
}

  .input-new-team-name {
    width: 31rem;
  }
