.shop-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3rem 2.5rem;

  .categories-card {
    @extend .card, .flex-1;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    padding: 2.25rem 3rem;

    .category-card {
      @extend .card;
      flex-direction: row;
      width: calc(50% - 2.5rem);
      max-width: calc(50% - 2.5rem);
      padding: 1.5rem 1.5rem 2.25rem;
      margin-bottom: 2.5rem;

      &__img {
        align-self: center;
        max-width: 18.75rem;
        max-height: 18.75rem;
      }
      &-content {
        padding-left: 3rem;
        flex-grow: 1;
      }
    }
  }
}

.order-info {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__row {
    width: 40rem;
    display: flex;
    margin-right: 16rem;
    margin-bottom: 1rem;
  }
}
