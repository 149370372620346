.tasks-page {
  display: flex;
  flex-direction: column;
  padding: 1.75rem 2.5rem 2rem 2.5rem;
  min-height: 100%;

  .tasks-modal-top {
    display: flex;
    margin-bottom: 2rem;

    &__btn {
      position: relative;
      height: 6rem;
      max-height: 6rem;
      width: 23.375rem;
      padding: 0 3.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: -2.25rem;
      @extend .md-text;

      &:first-child {
        margin-left: 0;
        z-index: 3;
      }
      &:nth-child(2) {
        z-index: 2;
      }
      &:nth-child(3) {
        z-index: 1;
      }
      div {
        z-index: 10;
      }
      svg {
        position: absolute;
        height: 6rem;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }
  }

  .table-row__cell .MuiFormControl-root .MuiInputBase-root {
    height: auto !important;
  }

  .tasks-t__modal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .test-preview-card {
    background: #ffffff;
    border: 0.125rem solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 1rem 2.5rem rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem;
    position: relative;
    padding: 2rem;
    font-size: 16px;
    flex-grow: 1;
    display: flex;

    &__left {
      width: 115rem;
      overflow-wrap: break-word;

      &-border-bottom {
        padding-bottom: 3rem;
        border-bottom: 0.1rem solid #cdcdcd;
      }
    }
    &__right {
      width: 15rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &__img {
      max-width: 60rem;
      max-height: 40rem;

      &-preview {
        max-height: 92vh;
        max-width: 92vw;
      }
    }
    &__gap {
      gap: 4rem;
    }
  }

  .test-result-card {
    background: #ffffff;
    border: 0.125rem solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 1rem 2.5rem rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem;
    padding: 2rem 1.5rem;

    &__title {
      padding: 0 1.5rem 2rem;
      margin: 0 -1.5rem 3rem;
      border-bottom: 0.125rem solid #ececec;
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      display: flex;
      align-items: center;
      flex: 0 1 50%;
      margin-bottom: 3rem;

      .textCmp:first-child {
        margin-right: 11.5rem;
      }
    }
  }

  .test-toggle-btns {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;

    .test-toggle-btn {
      padding: 1rem 2rem;
      border: 0.125rem solid #65a5dc;
      background: #fff;
      color: #65a5dc;
      @extend .md-text;
      transition: all 150ms ease-out;

      &:hover {
        background: #f1f1f1;
        color: #51a4ec;
        cursor: pointer;
      }

      &:first-child {
        border-radius: 0.75rem 0 0 0.75rem;
      }
      &:last-child {
        border-radius: 0 0.75rem 0.75rem 0;
      }

      &.active {
        background: #65a5dc;
        color: #fff;

        &:hover {
          background: #47a6fa;
          cursor: pointer;
        }
      }

      &.delete {
        color: #ee5454;
        background: #fff;

        &:hover {
          color: #fff;
          background: #ee5454;
          cursor: pointer;
        }
      }
    }
  }
  .test-new-block_btn {
    background: #ffffff;
    border: 0.125rem solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 1rem 2.5rem rgba(0, 0, 0, 0.14);
    border-radius: 0.75rem;
    position: relative;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.01);
    }
  }
  .MuiFormControlLabel-root,
  .MuiFormControlLabel-label {
    width: 100%;
  }
  .test-variant__item {
    border: 0.125rem solid #a6a6a6;
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
    margin-bottom: 1.5rem;

    input {
      height: 100%;
      flex: 0 1 100%;
      margin-right: 2rem;
      border: none;
      border-radius: 0.25rem;
      @extend .sm-text;
    }

    &__btn {
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
}
