.modal {
    @extend .card;
    flex-grow: 1;
    position: relative;

    .modal-header{
        display: flex;
        align-items: center;
        padding: 3rem 1.5rem;
        .h3-text {
            flex-grow: 1;
        }

        &__btn {
            cursor: pointer;
        }
    }
    .modal-content {
        flex-grow: 1;
        padding: 1.5rem 2rem;
        display: flex;
        flex-direction: column;

        .form-full-width {
            .MuiFormControl-root, .MuiInputBase-root {
                width: 100%;
            }
        }
        .ck-editor__editable_inline {
            min-height: 30.75rem;
            font-size: 2rem;
        }
    }
}